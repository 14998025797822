import React from 'react'

import { css } from '@emotion/core'
import { rem } from 'utils/typography'
import { ReactComponent as GithubIcon } from 'images/github.svg'

const githubLinkStyles = ({ size, loading }) => css`
   {
    border: 0;
    ${ '' /* padding: ${rem(10)}; */}
    margin: 0;
    padding-right: ${ rem(20) };
    padding-left: ${ rem(size + 10) };
    display: block;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 5px;
    margin: 4px;
    position: relative;
    height: ${ rem(size) };
    opacity: ${ loading ? 1 : 0.5 };
    background-color: #333;
    transition: background-color 0.2s ease-in-out;
    color: white;
    letter-spacing: ${ rem(0.4) };
    font-size: ${ rem(size / 2.8) };

    display: flex;
    flex-direction: row;
    align-items: center;

    text-decoration: none;
    pointer-events: auto;

    text-align: left;
    pointer-events: auto;
    &:active,
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: #222;
      cursor: pointer;
      outline: none;
    }
  }
`

const GithubButton = ({ size = 60, text, className, url, loading = true }) => (
  <div
    css={css`
      display: inline-block;
    `}
  >
    <a href={loading ? url : undefined} css={githubLinkStyles({ size, loading })}>
      <div
        css={css`
          width: ${ rem(size) };
          height: ${ rem(size) };
          position: absolute;
          top: -1px;
          left: 0;
          background-color: black;
          transition: background-color 0.3s;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        `}
      >
        <GithubIcon
          css={css`
            height: ${ rem(size / 1.6) };
            fill: white;
          `}
        />
      </div>

      <div>{loading ? text : 'Loading...'}</div>
    </a>
  </div>
)

export default GithubButton
